import { useLocation } from 'react-router-dom';
import { Wrapper } from '../wrapper';
import { useGetMostPost } from 'api/mostpost';
import { useGetHeroById } from 'api/heroById';

export const PublicPost = () => {
  const { search } = useLocation();

  const queryParams = new URLSearchParams(search);
  const id = queryParams.get('id');
  const { data: dataMost } = useGetMostPost({});

  const { data } = useGetHeroById(id as string, { enabled: !!id });

  return <Wrapper data={data} dataMost={dataMost} />;
};
