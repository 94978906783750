import { Col, Row, Typography, Image } from 'antd';
import InfoMerMasin from '../../../components/icons/info.png';
import InfoMerImg from '../../../components/icons/infoNew.png';

export const MerMasin = () => {
  const { Text, Paragraph } = Typography;

  return (
    <>
     
    <Row style={{ padding: '48px 0px 0px 48px' }}>
        <Col span={18}>
          <Col style={{ padding: '0px 0px 34px' }}>
            <Text style={{ color: '#4A4A4A', fontSize: 20, fontWeight: 700 }}>Մեր մասին</Text>
          </Col>
        </Col>
      </Row> 
      <Row gutter={[10, 10]} style={{padding:   '0 7px 0px 35px', marginRight: 0}}>
        <Col span={6} style={{display: 'flex', alignItems: 'end'}}><Image src={InfoMerImg} preview={false} /></Col>
        <Col span={13}>
          <Paragraph
            style={{
              color: '#7F7F7F',
              fontSize: 16,
              lineHeight: '26px',
              textAlign: 'justify',
            }}
          >
            Սույն կայքում տեղ գտնող նյութերը, վերլուծությունները, համադրումներն ու կարծիքներն իրենցից ներկայացնում են
            քաղաքական գործընթացների մոդելավորման ճանապարհին տարբեր մասնագետների կողմից՝ քաղաքական իրավիճակը հասկանալուն
            ուղղված կոլեկտիվ ջանք, խորհրդածություն, անհայտ կամ խրթին հարցերին պատասխաններ փնտրելու և լուծումներ գտնելու
            փորձ։ Այս աշխատանքներին մասնակցում են մաթեմատիկոսներ, քաղաքագետներ և տեղեկատվական տեխնոլոգիաների ոլորտի
            մասնագետներ։ Նախաձեռնությունը քաղաքացիական է, հետապնդում է հանրային՝ բոլորին հասանելի գիտելիքի ստեղծման և
            տարածման նպատակ՝ ի բարօրություն ինքնիշխան Հայաստանի Հանրապետության:
          </Paragraph>
          <Paragraph style={{ color: '#7F7F7F', lineHeight: '26px', textAlign: 'justify' }}>
              <Col style={{ fontSize: 16 }}>
                Կայքի «Մոդելավորում» բաժնում մենք փորձել ենք մոդելավորել քաղաքական գործընթացների դինամիկան, որը
                դիտարկում ենք որպես կոմպլեքս համակարգ՝ կազմված էլեմենտներից՝ տվյալ դեպքում երկրներից, որոնք բնութագրվում
                են կշիռով և ուժով, ինչպես նաև այլ էլեմենտների հետ կապերով, և էլեմենտները որոշակի ուժերի ազդեցության
                պայմաններում վերափոխվում են։ Արդյունքում, ենթադրում ենք, որ սկզբունքորեն կարելի է հաշվարկել ու
                կանխատեսել քաղաքական գործընթացի մասնակիցների վարքը և այդ համատեքստում ցանկացած էլեմենտի քաղաքական բարդ
                համակարգում ուղիներ գտնելու և կողմնորոշվելու հնարավորությունները: 
              </Col>
            </Paragraph>
            <Paragraph style={{ color: '#7F7F7F', lineHeight: '26px', textAlign: 'justify' }}>
              <Col style={{ fontSize: 16 }}>
                «Ռեսուրսներ» բաժնում զետեղված են այն նյութերն ու փաստաթղթերը, որոնք հիմք են ծառայել քաղաքական
                գործընթացների մոդելավորման համար։ Այստեղ կարող եք գտնել մեր դիտանկյունից բոլոր նշանակալից
                վերլուծությունները՝ կատարված  հայ և օտար մասնագետների կողմից, որոնք բնութագրում են աշխարհում տեղի
                ունեցող քաղաքական երևույթները։
              </Col>
            </Paragraph></Col>
        <Col span={6} style={{maxWidth: '25%', display: 'flex', justifyContent: 'center'}}>  <Image src={InfoMerMasin} preview={false} /></Col>
      </Row>

    </>
  );
};
