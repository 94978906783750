import React, { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { ArticleContextType } from './types';
type Post = {
  date: string;
  description: string;
  id: string;
  name: string;
  preview: string;
  status: string;
  update: string;
  view: number;
};
// Create a new context for the article-related data
const ArticleContext = createContext<ArticleContextType | null>(null);

// Create the ArticleWrapper component
export const ArticleWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [checkedTags, setCheckedTags] = useState<string[]>([]);
  const [posts, setPosts] = useState<Post[]>([]);
  const [totalPages, setTotalPages] = useState<number>();
  const [postsModel, setPostsModel] = useState<Post[]>([]);
  const [totalPagesModel, setTotalPagesModel] = useState<number>();
  const [selectedCategory, setSelectedCategory] = useState('');
  const [isPanelOpen, setPanelOpen] = useState(true);
  const [dataDynamic, setDataDynamic] = useState('');
  const [searchData, setSearchData] = useState<string>('');
  const [searchText, setSearchText] = useState('');



  const [params, setParams] = useState({
    page: 1,
    searchData: '',
    tags: [],
    category: '',
  });
  const [paramsModel, setParamsModel] = useState({
    page: 1,
    searchData: '',
    tags: [],
  });

  const contextValue = useMemo(
    () => ({
      checkedTags,
      setCheckedTags,
      posts,
      setPosts,
      totalPages,
      setTotalPages,
      totalPagesModel,
      setTotalPagesModel,
      postsModel,
      setPostsModel,
      setParams,
      params,
      selectedCategory,
      setSelectedCategory,
      setParamsModel,
      paramsModel,
      isPanelOpen,
      setPanelOpen,
      dataDynamic,
      setDataDynamic,
      setSearchData,
      searchData,
      setSearchText,
      searchText
    }),
    [checkedTags, dataDynamic, isPanelOpen, params, paramsModel, posts, postsModel, searchData, searchText, selectedCategory, totalPages, totalPagesModel]
  );

  return <ArticleContext.Provider value={contextValue}>{children}</ArticleContext.Provider>;
};

// Custom hook to access the article-related context
export const useArticle: () => ArticleContextType = () => {
  const articleContext = useContext(ArticleContext);

  if (!articleContext) {
    throw new Error('useArticle must be used within the ArticleWrapper.');
  }

  return articleContext;
};
