import { Col, Layout as LayoutComponent, Row, Input, Typography, Tag, Button } from 'antd';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Logo } from 'components/logo';
import { COLORS, PATHS } from 'helpers/constants';
import { JSXElementConstructor, ReactElement, ReactNode, ReactPortal, SetStateAction, useState } from 'react';
import { useGetAllPosts } from 'api/resources/use-get-posts';
import { GetAllModelavorum } from 'api/modelavorum';
import { useQueryClient } from '@tanstack/react-query';
import { useArticle } from 'context/wrapper';
import { SearchOutlined } from '@ant-design/icons';
import { GetAllModelUrlDynamic, GetAllModelavorumDynamic } from 'api/modelDynamic';
const { Header: HeaderComponent } = LayoutComponent;
const Wrapper = styled(HeaderComponent)`
  && {
    background: white;
    border-bottom: 1px solid #d6d6d6;
    position: fixed;
    width: 100%;
    z-index: 10;
    padding-inline: 30px;
    .ant-row {
      .ant-col {
        display: flex;
        align-items: center;
        transition: all ease 0.2s;
        &:last-child {
          border-left: 1px solid ${COLORS.SEARCH.BORDER};
          padding: 1rem;
          cursor: pointer;
        }
      }
    }
    a {
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
    }
  }
  .searchIcon {
    svg {
      fill: rgba(74, 74, 74, 0.5);
    }
  }
`;
const Menu = styled.nav`
  display: flex;
  gap: 3rem;
  height: 44px;
`;
export const Header = () => {
  const style = ({ isActive }: { isActive: boolean }) => ({
    color: isActive ? COLORS.MENU.ACTIVE : COLORS.MENU.NOT_ACTIVE,
  });
  const { setParams, params, setParamsModel, paramsModel, setSearchData, setCheckedTags, setSearchText, searchText } =
    useArticle();
  const [showPopup, setShowPopup] = useState(true);
  const [postsModel, setPostsModel] = useState<any>();
  const [posts, setPosts] = useState<any>();
  const [postsDynamic, setPostsDynamic] = useState<any>();
  const handleSearch = (value: SetStateAction<string>) => {
    setSearchText(value);
    setShowPopup(true);
  };
  const navigator = useNavigate();
  const { pathname } = useLocation();

  const handleClickId = (id: any) => {
    navigator(`/post?id=${id}`);
  };
  const handleClickIdResus = (id: SetStateAction<string>) => {
    navigator(`/model?id=${id}`);
  };
  const handleClickIdDinamik = (id: SetStateAction<string>) => {
    navigator(`/dynamic?id=${id}`);
  };
  const queryClient = useQueryClient();
  const handleClicModel = (path: string) => {
    navigator({
      pathname: path,
      search: `?search=${searchText}`,
    });
    setShowPopup(!showPopup);

    queryClient.invalidateQueries([
      GetAllModelUrlDynamic,
      {
        searchData: searchText,
        tags: [],
        page: 1,
        limit: 100,
      },
    ]);
  };
  const { Title } = Typography;

  useGetAllPosts(
    {
      searchData: searchText?.length >= 3 ? searchText : '',
      page: 1,
      limit: 100,
      tags: [],
      category: '',
    },
    {
      onSuccess: (response: { data: unknown; totalPages: number }) => {
        setPosts(response?.data);
      },
    }
  );
  GetAllModelavorum(
    {
      searchData: searchText?.length >= 3 ? searchText : '',
      page: 1,
      limit: 100,
      tags: [],
    },
    {
      onSuccess: (response: { data: unknown; totalPages: number }) => {
        setPostsModel(response?.data);
      },
    }
  );
  GetAllModelavorumDynamic(
    {
      searchData: searchText?.length >= 3 ? searchText : '',
    },
    {
      onSuccess: (response: any) => {
        setPostsDynamic(response);
      },
    }
  );
  const MAX_NAMES_TO_DISPLAY = 3;
  const PostName = posts?.slice(0, MAX_NAMES_TO_DISPLAY);
  const PostMoreNames = posts && posts.length > MAX_NAMES_TO_DISPLAY;
  const PostNameModal = postsModel?.slice(0, MAX_NAMES_TO_DISPLAY);
  const PostMoreNameModa = postsModel && postsModel.length > MAX_NAMES_TO_DISPLAY;

  const PostNameaDinamic = postsDynamic?.data?.rows?.slice(0, MAX_NAMES_TO_DISPLAY);
  const PostMoreNamesDinamic = postsDynamic?.data?.rows && postsDynamic?.data?.rows.length > MAX_NAMES_TO_DISPLAY;

  const handleLogoClick = () => {
    window.location.reload();
  };
  const navigateClick = () => {
    setSearchText(''),
      setParams({
        ...params,
        searchData: '',
      });
    setParamsModel({
      ...paramsModel,
      searchData: '',
    });
    setSearchData('');
    navigator(pathname);
    setShowPopup(false);
  };
  const handleSearchInputChange = () => {
    navigator(pathname);
    setParams({
      ...params,
      searchData: '',
    });
    setParamsModel({
      ...paramsModel,
      searchData: '',
    });
    setSearchData('');
    setCheckedTags([]);
    setShowPopup(false);
  };
  return (
    <Wrapper>
      <Row>
        <Col span={4} onClick={handleLogoClick}>
          <Logo />
        </Col>
        <Col span={16}>
          <Menu>
            <NavLink to="/" style={style} onClick={navigateClick}>
              Հիմնական
            </NavLink>
            <NavLink to="/models" style={style} onClick={navigateClick}>
              Մոդելավորում
            </NavLink>
            <NavLink to="/resources" style={style} onClick={navigateClick}>
              Ռեսուրսներ
            </NavLink>
            <NavLink to="/info" style={style} onClick={navigateClick}>
              Մեր մասին
            </NavLink>
          </Menu>
        </Col>
        <Col span={4} style={{ display: 'grid', height: 63 }}>
          <Row style={{ position: 'relative', top: 10 }}>
            <Input.Search
              prefix={<SearchOutlined className="searchIcon" />}
              size="large"
              placeholder="Որոնել"
              value={searchText}
              onChange={(e) => {
                if (searchText?.length < 3) {
                  handleSearchInputChange();
                }
                handleSearchInputChange();
                setSearchText(e.target.value);
              }}
              onSearch={handleSearch}
              suffix={
                searchText && (
                  <Button
                    type="link"
                    size="small"
                    onClick={(e) => {
                      setSearchText(''), e.preventDefault();
                      navigator(pathname);
                      setParams({
                        ...params,
                        searchData: '',
                      });
                      setParamsModel({
                        ...paramsModel,
                        searchData: '',
                      });
                      setSearchData('');
                      setCheckedTags([]);
                      setShowPopup(false);
                    }}
                    style={{ color: 'black' }}
                  >
                    X
                  </Button>
                )
              }
              onFocus={() => setShowPopup(false)}
            />
          </Row>
          <Row style={{ display: 'grid' }}>
            {!showPopup && searchText?.length >= 1 && (
              <Row
                justify={'center'}
                style={{
                  position: 'absolute',
                  right: '0px',
                  width: '100%',
                  padding: '5px 15px',
                  zIndex: '1',
                  minWidth: '178px',
                  background: 'white',
                  top: 64,
                  maxWidth: '75%',
                }}
              >
                <Col
                  style={{
                    lineHeight: '0',
                    display: 'grid',
                  }}
                  span={24}
                >
                  <Title level={5} style={{ borderBottom: '2px solid var(--grayDark)', fontWeight: '700' }}>
                    Ռեսուրսներ
                  </Title>
                  <Col style={{ borderLeft: 'none', display: 'block' }}>
                    {searchText?.length < 3 || PostName?.length === 0 ? (
                      <div style={{ color: '#C5C5C5', fontWeight: 700, textAlign: 'center' }}>no result</div>
                    ) : (
                      <>
                        {' '}
                        {PostName?.map(
                          (i: {
                            id: string;
                            name:
                              | string
                              | number
                              | boolean
                              | ReactElement<unknown, string | JSXElementConstructor<unknown>>
                              | Iterable<ReactNode>
                              | ReactPortal
                              | null
                              | undefined;
                          }) => (
                            <Tag
                              key={i?.id}
                              style={{
                                background: 'inherit',
                                border: 'none',
                                color: '#7F7F7F',
                                fontWeight: '700',
                                width: '100px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                              onClick={(e) => {
                                handleClickId(i?.id), e.preventDefault;
                              }}
                            >
                              {i?.name}
                            </Tag>
                          )
                        )}
                        {PostMoreNames && (
                          <Button
                            onClick={() => handleClicModel(PATHS.RESOURCES)}
                            style={{
                              border: 'none',
                              color: 'rgba(245, 194, 71, 1)',
                              fontWeight: '700',
                              marginLeft: '55%',
                            }}
                          >
                            Ավելին
                          </Button>
                        )}
                      </>
                    )}
                  </Col>
                </Col>
                <Col
                  span={24}
                  style={{
                    borderLeft: 'none',
                    padding: '0',
                    display: 'grid',
                    lineHeight: '0',
                  }}
                >
                  <Title level={5} style={{ borderBottom: '2px solid var(--grayDark)', fontWeight: '700' }}>
                    Մոդելը
                  </Title>

                  <Col style={{ borderLeft: 'none', display: 'block' }}>
                    {searchText?.length < 3 || (PostNameModal?.length === 0 && PostNameaDinamic?.length === 0) ? (
                      <div style={{ color: '#C5C5C5', fontWeight: 700, textAlign: 'center' }}>no result</div>
                    ) : (
                      <>
                        {PostNameModal?.map(
                          (i: {
                            id: string;
                            name:
                              | string
                              | number
                              | boolean
                              | ReactElement<unknown, string | JSXElementConstructor<unknown>>
                              | Iterable<ReactNode>
                              | ReactPortal
                              | null
                              | undefined;
                          }) => (
                            <Tag
                              key={i?.id}
                              style={{
                                background: 'inherit',
                                border: 'none',
                                color: '#7F7F7F',
                                fontWeight: '700',
                                width: '100px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                              onClick={(e) => {
                                handleClickIdResus(i?.id), e.preventDefault;
                              }}
                            >
                              {i?.name}
                            </Tag>
                          )
                        )}
                        {PostNameaDinamic?.map(
                          (i: {
                            id: string;
                            name:
                              | string
                              | number
                              | boolean
                              | ReactElement<unknown, string | JSXElementConstructor<unknown>>
                              | Iterable<ReactNode>
                              | ReactPortal
                              | null
                              | undefined;
                          }) => (
                            <Tag
                              key={i?.id}
                              style={{
                                background: 'inherit',
                                border: 'none',
                                color: '#7F7F7F',
                                fontWeight: '700',
                                width: '100px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                              onClick={(e) => {
                                handleClickIdDinamik(i?.id), e.preventDefault;
                              }}
                            >
                              {i?.name}
                            </Tag>
                          )
                        )}
                        {(PostMoreNameModa || PostMoreNamesDinamic) && (
                          <Button
                            onClick={() => handleClicModel(PATHS.MODELS)}
                            style={{
                              border: 'none',
                              color: 'rgba(245, 194, 71, 1)',
                              fontWeight: '700',
                              marginLeft: '55%',
                            }}
                          >
                            Ավելին
                          </Button>
                        )}
                      </>
                    )}
                  </Col>
                </Col>
              </Row>
            )}
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
};
