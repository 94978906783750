import { useLocation } from 'react-router-dom';
import { Wrapper } from '../wrapper';
import { useGetModelById } from 'api/resources/resus-by-id';
import { useGetMostPost } from 'api/mostpost';

export const PublicHero = () => {
  const { search } = useLocation();

  const queryParams = new URLSearchParams(search);
  const id = queryParams.get('id');
  const { data: dataMost } = useGetMostPost({});

  const { data } = useGetModelById(id as string, { enabled: !!id });

  return <Wrapper data={data} dataMost={dataMost} />;
};