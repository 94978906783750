import { CaretDownOutlined, CaretLeftOutlined, CaretRightOutlined, SearchOutlined } from '@ant-design/icons';
import { Row, Collapse, Typography, Input, Tag, Pagination, Button, Col } from 'antd';
import { useArticle } from 'context/wrapper';
import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { GetAllPostsUrl, useGetAllPosts } from 'api/resources/use-get-posts';
import { GetAllTags } from 'api/tags/get-all-tags';
import { GetAllModelUrl, GetAllModelavorum } from 'api/modelavorum';
import { ReactComponent as Ellipse } from '../icons/ellipse.svg';
import { ReactComponent as Ellipsep } from '../icons/ellipsep.svg';
import { useLocation } from 'react-router-dom';
import { GetAllModelUrlDynamic, GetAllModelavorumDynamic } from 'api/modelDynamic';

interface Tag {
  label?: string;
  id?: any;
}

export const Tags = () => {
  const queryClient = useQueryClient();
  const { pathname } = useLocation();
  const [isHovered, setIsHovered] = useState(false);

  const { Panel } = Collapse;
  const { Title } = Typography;
  const { Search } = Input;
  const { CheckableTag } = Tag;
  const {
    checkedTags,
    setCheckedTags,
    setPosts,
    setTotalPages,
    setPostsModel,
    setTotalPagesModel,
    setSelectedCategory,
    isPanelOpen,
    setPanelOpen,
    setDataDynamic,
    setSearchText,
  } = useArticle() ?? {};

  const [params, setParams] = useState({
    page: 1,
    searchData: '',
  });
  const { data }: { data: any } = GetAllTags(params);

  const handleTagChange = (tagId: string) => {
    if (checkedTags?.includes(tagId)) {
      setCheckedTags(checkedTags.filter((id) => id !== tagId));
    } else {
      setCheckedTags([...checkedTags, tagId]);
    }
    setSelectedCategory('');
  };

  const [search, setSearch] = useState<string>();

  useEffect(() => {
    setSearch('');

    if (search !== '') {
      setParams({
        ...params,
        searchData: '',
      });
    }
    setCheckedTags([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, setCheckedTags]);

  useEffect(() => {
    if (checkedTags.length > 0) {
      queryClient.invalidateQueries({
        queryKey: [
          GetAllPostsUrl,
          {
            searchData: '',
            tags: checkedTags,
          },
        ],
      });
    }
    if (checkedTags.length > 0) {
      queryClient.invalidateQueries({
        queryKey: [
          GetAllModelUrl,
          {
            searchData: '',
            page: 1,
            limit: 10,
            tags: checkedTags,
          },
        ],
      });
    }
    if (checkedTags.length > 0) {
      queryClient.invalidateQueries({
        queryKey: [
          GetAllModelUrlDynamic,
          {
            searchData: '',
            tags: checkedTags,
          },
        ],
      });
    }
  }, [checkedTags, queryClient, params, setCheckedTags]);

  useGetAllPosts(
    {
      page: 1,
      limit: 10,
      tags: checkedTags,
      category: '',
      searchData: '',
    },
    {
      onSuccess: (response: { data: any; totalPages: any }) => {
        setPosts(response?.data);
        setTotalPages(response?.totalPages);
      },
    }
  );

  GetAllModelavorum(
    {
      page: 1,
      limit: 100,
      tags: checkedTags,
      searchData: '',
    },
    {
      onSuccess: (response: { data: any; totalPages: any }) => {
        setPostsModel(response?.data);
        setTotalPagesModel(response?.totalPages);
      },
    }
  );
  GetAllModelavorumDynamic(
    {
      page: 1,
      limit: 100,
      tags: checkedTags,
      searchData: '',
    },
    {
      onSuccess: (response: any) => {
        setDataDynamic(response);
      },
    }
  );
  const dataSource = Array.isArray(data?.data) ? data?.data : [];

  const onSearch = (value: string) => {
    setParams({
      ...params,
      searchData: value,
      page: 1,
    });
  };

  const handlePageChange = (page: number) => {
    setParams({
      ...params,
      page,
    });
  };

  const handleClearChecked = () => {
    setCheckedTags([]);
    setSearchText('');
  };

  const customCheckedTag = {
    backgroundColor: 'rgb(244, 195, 82)',
    color: 'rgba(74, 74, 74, 1)',
    display: 'flex',
    alignItems: 'center',
    fontWeight: '700',
    boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.16)',
  };

  const itemRender = (current: number, type: string, originalElement: any) => {
    if (type === 'prev') {
      return <CaretLeftOutlined rev={undefined} />;
    }
    if (type === 'next') {
      return <CaretRightOutlined rev={undefined} />;
    }
    if (type === 'page') {
      return current === params.page ? <Ellipse /> : <Ellipsep />;
    }
    return originalElement;
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div style={{ background: '#f7d88f', width: '100%', position: 'relative', top: '63px', zIndex: 1 }}>
      <Collapse
        style={{ background: 'var(--yelloLight)', borderRadius: 'unset', border: 'none' }}
        collapsible="header"
        activeKey={!isPanelOpen ? 'tags-panel' : undefined}
      >
        <Panel
          showArrow={false}
          header={
            <Row onClick={() => setPanelOpen(!isPanelOpen)}>
              <Title
                level={5}
                style={{
                  paddingLeft: '48px',
                  fontWeight: '700',
                  fontSize: '16px',
                  color: '#4A4A4A',
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '5px',
                  position: 'relative',
                  top: '4px',
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {isPanelOpen ? (
                  <CaretDownOutlined
                    rev={undefined}
                    style={{
                      transform: isHovered ? 'translateX(0px)' : 'rotate(-90deg)',
                      transition: 'transform 0.3s',
                    }}
                  />
                ) : (
                  <CaretDownOutlined rev={undefined} />
                )}
                Պիտակներ{' '}
              </Title>
              {!isPanelOpen && (
                <Row onClick={(e): void => e.stopPropagation()}>
                  <Col>
                    <Search
                      prefix={<SearchOutlined rev={undefined} />}
                      placeholder="Որոնել"
                      style={{
                        marginLeft: '10px',
                        borderBottom: '1px solid rgb(74, 74, 74)',
                        background: 'unset',
                      }}
                      onChange={(ev: any) => setSearch(ev.target.value)}
                      allowClear
                      onSearch={onSearch}
                      defaultValue={params.searchData}
                      bordered={false}
                    />
                  </Col>
                  {checkedTags?.length === 0 ? (
                    ''
                  ) : (
                    <Col style={{ position: 'absolute', right: '190px' }}>
                      <Button
                        onClick={handleClearChecked}
                        style={{ border: 'none', background: 'inherit', color: '#7F7F7F', fontWeight: 700 }}
                        className="resusHome"
                      >
                        Մաքրել
                      </Button>
                    </Col>
                  )}

                  <Col style={{ position: 'absolute', right: '10px' }}>
                    {' '}
                    <Pagination
                      style={{ textAlignLast: 'center' }}
                      current={params.page}
                      pageSize={10}
                      total={data?.totalPages ? data?.totalPages * 10 : 0}
                      onChange={handlePageChange}
                      showSizeChanger={false}
                      itemRender={itemRender}
                    />
                  </Col>
                </Row>
              )}
            </Row>
          }
          key="tags-panel"
        >
          <Row style={{ columnGap: '8px', rowGap: '8px' }}>
            {dataSource?.map((tag: Tag) => (
              <CheckableTag
                key={tag.label}
                checked={checkedTags?.includes(tag.id)}
                onChange={() => handleTagChange(tag.id)}
                style={checkedTags?.includes(tag.id) ? customCheckedTag : { fontSize: '14px', height: 24 }}
              >
                {tag.label}
              </CheckableTag>
            ))}
          </Row>
        </Panel>
      </Collapse>
    </div>
  );
};
