import { useQuery } from '@tanstack/react-query';

import client from 'api/client';
export const url = '/public/getTags';

export const GetAllTags = (params: { page: number; searchData: string }, options = { enabled: true }) => {
  const result = useQuery(
    [url, params],
    async () => {
      const response = await client.post(`${url}/${params.page}/${16}`, {
        searchData: params.searchData,
      });
      return response;
    },
    options
  );

  const { data, isSuccess } = result;

  return {
    ...result,
    data: isSuccess ? data : [],
  };
};
