import { Col, Row } from 'antd';
import { useGetMostPost } from 'api/mostpost';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

// eslint-disable-next-line react-hooks/rules-of-hooks

export const Most = () => {
  const { data: dataMost }: { data: any } = useGetMostPost({});
  const navigator = useNavigate();
  const handleDivClick = (id: any) => {
    navigator(`/post?id=${id}`);
  };

  return (
    <div>
      {dataMost?.map((titl: any) => (
        <>
          <Row
            onClick={() => handleDivClick(titl.id)}
            style={{
              padding: '11px 0px',
              overflow: 'hidden',
              fontSize: 16,
              maxWidth: '15vw',
            }}
          >
            <Col style={{ fontSize: '14px', color: 'var(--grayDark)' }} span={24}>
              {titl?.author}
            </Col>
            <Col style={{ fontWeight: '700', fontSize: '16px', color: 'var(--grayDark)' }} span={24}>
              {titl?.title}
            </Col>
            <div style={{ fontSize: '14px', color: 'var(--lightgray)' }}>
              {moment(titl?.date).isValid() ? moment(titl?.date).format('YYYY-MM-DD') : ''}
            </div>
          </Row>
        </>
      ))}
    </div>
  );
};
