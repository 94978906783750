import { useQuery } from '@tanstack/react-query';

import client from 'api/client';

export const GetAllModelUrlDynamic = '/public/dynamic/getModels';

export const GetAllModelavorumDynamic = (
  params: any & {
    searchData: string;
    page: number;
    limit: number;
    tags: string[];
  },
  options?: any
): any => {
  const result = useQuery(
    [GetAllModelUrlDynamic, params],
    async () => {
      const response = await client.post(`${GetAllModelUrlDynamic}/${1}/${100}`, {
        searchData: params.searchData,
        tags: params.tags,
      });
      return response;
    },
    options
  );
  const { data, isSuccess } = result;

  return {
    ...result,
    data: isSuccess ? data : [],
  };
};
