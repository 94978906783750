import { Button, Col, Row, Tag } from 'antd';
import { ResourcesPost } from './resources-post';
import { useState } from 'react';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { GetAllCategory } from 'api/category/get-all-category';
import { useQueryClient } from '@tanstack/react-query';
import { urlByIdCategory } from 'api/category/get-by-category';
import { useArticle } from 'context/wrapper';
import { GetAllPostsUrl, useGetAllPosts } from 'api/resources/use-get-posts';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATHS } from 'helpers/constants';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
export const Resources = () => {
  const queryClient = useQueryClient();
  const { setPosts, setTotalPages, setCheckedTags, checkedTags, selectedCategory, setSelectedCategory } = useArticle();
  const [isHovered, setIsHovered] = useState(false);

  const navigator = useNavigate();
  const { pathname } = useLocation();
  const handleButtonClick = (): void => {
    navigator(PATHS.RESOURCES);
  };

  //Category
  const {
    data: { data: category },
  } = GetAllCategory({
    page: 1,
    limit: 50,
  });

  const handleClick = (category: string) => {
    setSelectedCategory(category);
    setCheckedTags([]);
    queryClient.invalidateQueries({
      queryKey: [
        urlByIdCategory,
        {
          categoryName: category,
        },
      ],
    });
  };

  useGetAllPosts(
    {
      page: 1,
      category: selectedCategory,
      searchData: '',
      tags: [],
    },
    {
      enabled: !!selectedCategory,
      onSuccess: (data: { totalPages(totalPages: number): unknown; data: string }) => {
        setPosts(data?.data);
        setTotalPages(data?.totalPages);
      },
    }
  );

  const GetAllCAtegory = () => {
    queryClient.invalidateQueries({
      queryKey: [
        GetAllPostsUrl,
        {
          searchData: '',
          tags: [],
        },
      ],
    });
    setCheckedTags([]);
    setSelectedCategory('');
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const items =
    category &&
    category?.map((item: { id: string; label: string; postId: string[] }) => {
      const isSelected = item.id === selectedCategory;

      return (
        <Tag
          key={item.id}
          style={{
            color: isSelected ? '#4A4A4A' : checkedTags?.length >= 1 && isSelected ? 'red' : 'rgba(74, 74, 74, 0.5)',
            fontWeight: '700',
            fontSize: '16px',
            border: 'none',
            background: 'var(--yello)',
            padding: '0px 20px'

          }}
          onClick={(e: { preventDefault: () => any }) => {
            handleClick(item.id), e.preventDefault();
          }}
          className={isSelected ? 'selectedCategory' : ''}
        >
          <span style={{  cursor: 'pointer',}}>   {item.label}</span>
       
        </Tag>
      );
    });
  const customPrevButton = (
    <div className="custom-prev-button">
      <CaretLeftOutlined rev={undefined} />
    </div>
  );

  const customNextButton = (
    <div className="custom-next-button">
      <CaretRightOutlined rev={undefined} />
    </div>
  );
  return (
    <Row style={{ position: 'relative', top: '62px', background: 'var(--yello)' }}>
      <Col span={6} style={{ height: pathname === '/resources' ? 73 : 64 }}>
        <button
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleButtonClick}
          style={{
            backgroundColor: 'inherit',
            transition: 'background-color 0.3s',
            background: 'var(--yello)',
            border: 'none',
            fontWeight: '700',
            color: '#4A4A4A',
            borderBottom: '2px solid #4A4A4A',
            marginLeft: '50px',
            borderRadius: 'unset',
            padding: 'unset',
            position: 'relative',
            top: pathname === '/' ? 22 : 28,
            fontSize: '16px',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',

          }}
        >
          Ռեսուրսներ
          {pathname === '/resources' ? (
            ''
          ) : (
            <CaretRightOutlined
              style={{
                transform: isHovered ? 'translateX(10px' : 'rotate(0deg)',
                transition: 'transform 0.3s',
                paddingLeft: 8,
              }}
            />
          )}
        </button>
      </Col>
      {pathname === '/' ? (
        <Row style={{ paddingTop: '50px' }}></Row>
      ) : (
        <Col span={18} style={{ display: 'flex' }}>
          <Col span={3} style={{ display: 'flex', alignItems: 'center' }}>
            {' '}
            <Button onClick={GetAllCAtegory} className={selectedCategory === '' ? ' allbutton' : 'selectedCategoryAll'}>
              Բոլորը
            </Button>
          </Col>
          <Col span={20} style={{ display: 'flex', alignItems: 'center', position: 'relative', top: 12 }}>
            <AliceCarousel
              items={items}
              animationDuration={200}
              autoWidth
              mouseTracking
              touchTracking
              disableDotsControls={true}
              renderPrevButton={() => customPrevButton}
              renderNextButton={() => customNextButton}
            />
          </Col>
        </Col>
      )}
      <ResourcesPost />
    </Row>
  );
};
