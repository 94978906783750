import React, { useEffect, useState } from 'react';
import G6 from '@antv/g6';
import { customData } from './data';

export const Chart: React.FC = () => {
  const [graph, setGraph] = useState<any>(null);

  useEffect(() => {
    const container: any = document.getElementById('container');
    const width = container.scrollWidth;
    const height = container.scrollHeight || window.innerHeight;

    const graphInstance: any = new G6.TreeGraph({
      container: 'container',
      width,
      height,
      linkCenter: true,
      modes: {
        default: ['drag-canvas', 'zoom-canvas'],
      },
      defaultNode: {
        size: 26,
        style: {
          fill: '#4A4A4A;',
          stroke: '#F5C247',
          lineWidth: 5,
        },
      },
      defaultEdge: {
        type: 'cubic-vertical',
      },
      layout: {
        type: 'compactBox',
        direction: 'TB',
        getWidth: () => 60,
        getVGap: () => 10,
      },
    });

    graphInstance.node(function (node: any) {
      let position = 'right';
      let rotate = 0;
      let label = node.id;

      if (!node.children) {
        position = 'bottom';
        rotate = Math.PI / 2;
      }

      if (label.length > 10) {
        label = label.substring(0, 8) + '...';
      }

      return {
        label,
        labelCfg: {
          position,
          offset: 5,
          style: {
            rotate,
            textAlign: 'start',
          },
        },
      };
    });


    graphInstance.on('node:mouseenter', (e: any) => {
      const item = e.item;
      const model = item.getModel();
      const label = model.id;
      const tooltip = document.createElement('div');
      tooltip.className = 'node-tooltip';
      tooltip.innerHTML = label;
      tooltip.style.position = 'absolute';
      tooltip.style.left = `${e.canvasX}px`;
      tooltip.style.top = `${e.canvasY}px`;
      tooltip.style.backgroundColor = 'white';
      tooltip.style.fontWeight = 'bold';
      tooltip.style.fontSize = '12px';

      container.appendChild(tooltip);
    });

    graphInstance.on('node:mouseleave', () => {
      const tooltips = document.querySelectorAll('.node-tooltip');
      tooltips.forEach((tooltip) => tooltip.remove());
    });




    graphInstance.data(customData);
    graphInstance.render();

    graphInstance.on('node:dblclick', (e: any) => {
      const item = e.item;
      const model = item.getModel();
      model.collapsed = !model.collapsed;
      graphInstance.refreshLayout();
    });

    setGraph(graphInstance);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const container = document.getElementById('container');
      if (typeof window !== 'undefined') {
        window.onresize = () => {
          if (!graph || graph.get('destroyed')) return;
          if (!container || !container.scrollWidth || !container.scrollHeight) return;
          graph.changeSize(container.scrollWidth, container.scrollHeight);
        };
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [graph]);

  return <div id="container" />;
};
