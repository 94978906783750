import { useLocation } from 'react-router';
import {
  FacebookFilled,
  LinkOutlined,
  LinkedinFilled,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  TwitterOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { Button, Col, Row, Spin, Tag, Typography, Image, Space } from 'antd';
import moment from 'moment';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';
import { Most } from 'components/hero/MostViv';
import { Header } from '../../components/headers';
import { useEffect, useState } from 'react';
import { useArticle } from 'context/wrapper';

const { Text, Paragraph } = Typography;

export const Wrapper = ({ data }: any) => {
  const { setSelectedCategory } = useArticle();

  const date = moment(data?.date);

  const { pathname, search } = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setSelectedCategory('');
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(loadingTimeout);
  }, [setSelectedCategory]);
  if (isLoading) {
    return (
      <div>
        <Header />
        <Row justify={'center'}>
          <Spin size="large" style={{ position: 'absolute', top: '50%', left: '50%' }} />
        </Row>
      </div>
    );
  }
  {
  }

  const shareUrl = `https://nationstate.am${pathname}?id=${data?.id}`;
  return (
    <div>
      <Header />
      <div style={{ position: 'relative', top: '64px' }}>
        <Row wrap={false}>
          <Col
            span={4}
            style={{
              display: 'flex',
              justifyContent: 'end',
              placeItems: 'end',
              borderLeft: '1px solid  #D6D6D6',
              paddingRight: '1px solid  #D6D6D6',

              color: 'var(--lightgray)',
              fontSize: '12px',
              padding: '0px 10px',
            }}
          >
            {data?.date === null || data?.date === '' ? '' : date?.format('YYYY-MM-DD')}
          </Col>
          <Col
            span={12}
            style={{
              fontWeight: '700',
              padding: '1% 12px 3px',
              borderRight: '1px solid  #D6D6D6',
              borderLeft: '1px solid  #D6D6D6',
              color: 'var(--grayDark)',
              fontSize: '24px',
              height: 'auto',
              overflow: 'hidden',
              wordWrap: 'break-word',
              whiteSpace: 'pre-line',
            }}
          >
            {data?.title}
          </Col>
          <Col
            flex={'176px'}
            style={{
              display: 'flex',
              justifyContent: 'end',
              placeItems: 'end',
              color: 'var(--lightgray)',
              fontSize: '12px',
              padding: '0 16px',
            }}
          >
            {data?.author === null
              ? ''
              : 'Հեղինակ :'}{' '}
            {data?.author}
          </Col>
          <Col
            flex={'auto'}
            style={{
              display: 'flex',
              placeItems: 'end',
              columnGap: '16px',
              paddingLeft: '12px',
              borderLeft: '1px solid  #D6D6D6',
              borderBottom: '1px solid  #D6D6D6',
              position: 'relative',
              top: '1px',
              padding: '0 16px',
            }}
          >
            <TwitterShareButton url={'https://nationstate.am' + pathname + search}>
              <TwitterOutlined style={{ color: 'rgba(162, 162, 162, 1)', fontSize: 'large' }} rev={undefined} />
            </TwitterShareButton>
            <FacebookShareButton url={'https://nationstate.am' + pathname + search}>
              <FacebookFilled style={{ color: 'rgba(162, 162, 162, 1)', fontSize: 'large' }} rev={undefined} />
            </FacebookShareButton>
            <LinkedinShareButton url={'https://nationstate.am' + pathname + search}>
              <LinkedinFilled style={{ color: 'rgba(162, 162, 162, 1)', fontSize: 'large' }} rev={undefined} />
            </LinkedinShareButton>
            <Button style={{ height: 'unset', padding: 'unset', border: 'unset' }}>
              <Paragraph
                style={{ marginBottom: 'unset' }}
                copyable={{
                  text: shareUrl,
                  icon: [<LinkOutlined key={'copy'} style={{ color: 'rgba(162, 162, 162, 1)', fontSize: 'large' }} />],
                }}
              />
            </Button>
          </Col>
        </Row>
        <Row wrap={false}>
          <Col
            span={4}
            style={{
              padding: '1px solid  #D6D6D6',
              borderLeft: '1px solid  #D6D6D6',
              borderTop: '1px solid  #D6D6D6',
            }}
          ></Col>
          <Col
            span={12}
            style={{
              borderRight: '1px solid  #D6D6D6',
              borderLeft: '1px solid  #D6D6D6',
              borderTop: '1px solid  #D6D6D6',
              fontSize: '18px',
              color: 'rgba(74, 74, 74, 1)',
              fontWeight: '400',
              lineHeight: '32px',
            }}
          >
            {data?.videoURL && (
               <iframe
               width="96%"
               height="540px"
               src={
                data?.videoURL.includes('youtube.com/playlist')
                   ? `https://www.youtube.com/embed/videoseries?list=${new URL(data?.videoURL).searchParams.get('list')}`
                   : data?.videoURL.includes('youtube.com/watch')
                     ? `https://www.youtube.com/embed/${new URL(data?.videoURL).searchParams.get('v')}`
                     : `https://www.youtube.com/embed/${data?.videoURL.split('/').pop()}`
               }
               title="YouTube video playlist player"
               frameBorder="0"
               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
               allowFullScreen
               style={{ padding: 12 }}
             ></iframe>
            )}

            <Col style={{ display: 'flex', justifyContent: 'center' }}>
              {data?.preview ? (
                <Image
                  src={data?.preview}
                  style={{ width: '100%', objectFit: 'contain', maxHeight: '65vh', padding: '16px 0px' }}
                  alt=""
                  preview={{
                    toolbarRender: (
                      _,
                      {
                        transform: { scale },
                        actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
                      }
                    ) => (
                      <Space size={12} className="toolbar-wrapper">
                        <SwapOutlined rotate={90} onClick={onFlipY} />
                        <SwapOutlined onClick={onFlipX} />
                        <RotateLeftOutlined onClick={onRotateLeft} />
                        <RotateRightOutlined onClick={onRotateRight} />
                        <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                        <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                      </Space>
                    ),
                  }}
                />
              ) : null}
            </Col>
            <div className="getRexsusName" dangerouslySetInnerHTML={{ __html: data?.description }}></div>
          </Col>
          <Col
            flex={'176px'}
            style={{
              padding: '1px solid  #D6D6D6',
              borderTop: '1px solid  #D6D6D6',
              paddingTop: '2%',
            }}
          ></Col>
          <Col
            flex="auto"
            style={{
              borderLeft: '1px solid  #D6D6D6',
            }}
          >
            {data?.tags?.length === 0 ? (
              ''
            ) : (
              <Typography style={{ fontWeight: '700', color: 'var(--grayDark)', padding: '16px' }}>Պիտակներ</Typography>
            )}
            {data?.tags?.map((label: any) => (
              <Col key={label} style={{ padding: '0 16px' }}>
                <Tag
                  key={label}
                  bordered={false}
                  style={{
                    color: '#A2A2A2',
                    background: '#FCECC7',
                    fontSize: '14px',
                    margin: '5px 0px',
                  }}
                >
                  {label?.label}
                </Tag>
              </Col>
            ))}
            {pathname === '/model' || pathname === '/dynamic' ? (
              ''
            ) : (
              <div style={{ marginTop: '10%' }}>
                <Typography
                  style={{
                    paddingBottom: '24px',
                    fontWeight: '700',
                    color: 'var(--grayDark)',
                    borderTop: '1px solid  #D6D6D6',
                    padding: '16px',
                  }}
                >
                  Ամենաշատ դիտվածները
                </Typography>
                <>
                  <div
                    style={{
                      display: 'grid',
                      gap: '24px',
                      width: 'calc(91% - 20px)',
                      padding: '16px',
                      cursor: 'pointer',
                    }}
                  >
                    <Most />
                  </div>
                </>
              </div>
            )}
          </Col>
        </Row>
        {data?.html?.map((element: any, index: number) => (
          <Row key={index}>
            <Col
              span={4}
              style={{
                borderLeft: '1px solid  #D6D6D6',
              }}
            >
              <Col
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  padding: '0 10px',
                  color: 'var(--lightgray)',
                  fontSize: '12px',
                  position: 'relative',
                  borderTop: ' 1px solid rgb(214, 214, 214)',
                }}
              >
                {element.subtitle_1?.slice(0, 10)}
              </Col>
            </Col>

            <Col
              span={12}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                borderRight: '1px solid  #D6D6D6',
                borderLeft: '1px solid  #D6D6D6',
                color: 'var(--grayDark)',
                fontSize: '18px',
                fontWeight: '400',
                lineHeight: '32px',
              }}
            >
              <Text
                style={{
                  fontSize: '18px',
                  fontWeight: '700',
                  color: 'var(--grayDark)',
                  borderTop: '1px solid rgb(214, 214, 214)',
                  display: 'flex',
                  padding: '0 12px',
                  width: '100%',
                }}
              >
                {element.paragraph}
              </Text>
              <div className="getResusName" dangerouslySetInnerHTML={{ __html: element.descriptionAdd }}></div>
            </Col>
            <Col
              flex={'176px'}
              style={{
                borderRight: '1px solid  #D6D6D6',
                position: 'relative',
                left: '1px',
              }}
            >
              <Col
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  padding: '0 10px',
                  color: 'var(--lightgray)',
                  fontSize: '12px',
                  position: 'relative',
                  borderTop: ' 1px solid rgb(214, 214, 214)',
                }}
              >
                {element.subtitle_2?.slice(0, 10)}
              </Col>
            </Col>
            <Col
              flex={'auto'}
              style={{
                display: 'flex',
                placeItems: 'end',
                columnGap: '16px',
                paddingLeft: '12px',
                borderTop: '1px solid  #D6D6D6',
                position: 'relative',
              }}
            ></Col>
          </Row>
        ))}
        <Row style={{ marginBottom: '0', background: '#F5C247' }} className="referPrev">
          <Col span={4}>
            <Col
              style={{
                display: 'flex',
                justifyContent: 'end',
                padding: '0 10px',
                color: 'var(--lightgray)',
                fontSize: '12px',
                position: 'relative',
                top: '10px',
              }}
            ></Col>

            <Col style={{ height: '28px', borderBottom: '1px solid  rgba(111, 111, 111, 1)' }}></Col>
          </Col>
          {data?.descriptionReferences && (
            <Col
              span={12}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                borderRight: '1px solid  rgba(111, 111, 111, 1)',
                borderLeft: '1px solid  rgba(111, 111, 111, 1)',
                color: 'var(--grayDark)',
                fontSize: '18px',
                fontWeight: '400',
                lineHeight: '32px',
              }}
            >
              <Text
                style={{
                  fontSize: '18px',
                  fontWeight: '700',
                  color: 'var(--grayDark)',
                  borderBottom: '1px solid rgba(111, 111, 111, 1)',
                  display: 'flex',
                  padding: '0 12px',
                  width: '100%',
                }}
              >
                Ծանոթագրություն
              </Text>
              <div
                className="getResusName getReference"
                dangerouslySetInnerHTML={{ __html: data?.descriptionReferences }}
              ></div>
            </Col>
          )}
          <Col
            flex={'176px'}
            style={{
              borderRight:
                data?.descriptionReferences === undefined || data?.descriptionReferences === null
                  ? ''
                  : '1px solid  rgba(111, 111, 111, 1)',
              position: 'relative',
              left: '1px',
            }}
          >
            <Col
              style={{
                display: 'flex',
                justifyContent: 'end',
                padding: '0 10px',
                color: 'var(--lightgray)',
                fontSize: '12px',
                position: 'relative',
                top: '10px',
              }}
            ></Col>

            <Col style={{ height: '28px', borderBottom: '1px solid  rgba(111, 111, 111, 1)' }}></Col>
          </Col>
          <Col flex={'auto'}>
            <Col
              style={{
                display: 'flex',
                justifyContent: 'end',
                padding: '0 10px',
                color: 'var(--lightgray)',
                fontSize: '12px',
                position: 'relative',
                top: '10px',
              }}
            ></Col>

            <Col style={{ height: '28px', borderBottom: '1px solid  rgba(111, 111, 111, 1)' }}></Col>
          </Col>
        </Row>
      </div>
    </div>
  );
};
