import { Row, Col, Typography, Image } from 'antd';
import Left from '../../../components/icons/left.png';
import Team1 from '../../../components/icons/team1.png';
import Team2 from '../../../components/icons/team2.png';

export const MerTimy = () => {
  const { Text } = Typography;

  return (
    <>
      <Row style={{ padding: '48px 0px 0px 48px' }}>
        <Col span={24}>
          <Col>
            <Text style={{ color: '#4A4A4A', fontSize: 20, fontWeight: 700 }}>Մեր թիմը</Text>
          </Col>
        </Col>
      </Row>
      <Row justify={'space-between'} style={{position: 'relative', top: 148}}>
        <Col>  <Image src={Left} preview={false} />  
    </Col>
        <Col><Image src={Left} preview={false} /></Col>
      </Row>
      <Row justify={'center'} gutter={[148, 0]} style={{width: '100%', marginLeft: 0}}>
          <Col> <Image src={Team1} preview={false} /></Col>  
           <Col><Image src={Team2} preview={false} /></Col>  </Row>
    </>
  );
};
