import { useQuery } from '@tanstack/react-query';

import client from 'api/client';

export const urlByIdCategory = '/admin/resource/getAll';

export const useGetByCategory = (
  params: any & {
    category: string;
    page: number;
    limit: number;
  },
  options?: any
): any => {
  const result = useQuery({
    queryKey: [urlByIdCategory, params],
    queryFn: async () => {
      const response = await client.post(`${urlByIdCategory}/${params.page}/${100}`, {
        category: params.category,
        searchData: params.searchData,
        tags: params.tags,
      });
      return response;
    },
    ...options,
  });

  const { data, isSuccess } = result;

  return {
    ...result,
    data: isSuccess ? data : [],
  };
};
