import { Col, Row } from 'antd';
import { Models } from 'pages/models';
import { Resources } from 'pages/resources';

export const Home = () => {
  return (
    <Row>
      <Col span={12}><Models/></Col>
      <Col span={12}><Resources/></Col>
    </Row>
  );
};
