import { useLocation } from 'react-router-dom';
import { Wrapper } from '../wrapper';
import { useGetResourceById } from 'api/modelavorum/model-by-id';
import { useGetMostPost } from 'api/mostpost';

export const PublicModel = () => {
  const { search } = useLocation();

  const queryParams = new URLSearchParams(search);
  const id = queryParams.get('id');
  const { data: dataMost } = useGetMostPost({});

  const { data } = useGetResourceById(id as string, { enabled: !!id });

  return <Wrapper data={data} dataMost={dataMost} />;
};