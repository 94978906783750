import React, { useEffect } from 'react';
import { Col, Row, Typography } from 'antd';
import { GetAllModelavorumDynamic } from 'api/modelDynamic';
import moment from 'moment';
import { useArticle } from 'context/wrapper';
import { useLocation, useNavigate } from 'react-router-dom';

export const Dinamik: React.FC = () => {
  const { dataDynamic, setDataDynamic, setSearchData, searchData } = useArticle() ?? {};
  const { search } = useLocation();

  GetAllModelavorumDynamic(
    {
      searchData: searchData,
    },
    {
      onSuccess: (response: any) => {
        setDataDynamic(response);
      },
    }
  );

  const { isPanelOpen } = useArticle() ?? {};
  const { Title } = Typography;
  const navigator = useNavigate();
  const handleClickIdResus = (id: any) => {
    navigator(`/dynamic?id=${id}`);
  };

  useEffect(() => {
    if (search !== '') {
      setSearchData(decodeURI(search.replace('?search=', '')));
    }
  }, [search, setSearchData]);

  return (
    <Row
      style={{
        height: isPanelOpen ? 'calc(91vh - 95px)' : 'calc(91vh - 145px)',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      {dataDynamic?.data?.rows?.map(
        (item: {
          id: React.Key | null | undefined;
          name:
          | string
          | number
          | boolean
          | React.ReactElement<any, string | React.JSXElementConstructor<any>>
          | Iterable<React.ReactNode>
          | React.ReactPortal
          | null
          | undefined
          | any;
          description: any;
          date: any;
          videoURL: string | undefined;
        }) => (
          <Row key={item?.id} gutter={[60, 10]} style={{ padding: '10px 23px 10px 33px', height: 200 }} className="hover-effect">
            <Col
              span={15}
              onClick={(e) => {
                handleClickIdResus(item?.id), e.preventDefault;
              }}
              style={{ cursor: 'pointer', padding: '10px 0', display: 'grid', alignItems: 'center', width: '100vw' }}
            >
              {' '}
              <Col>
                <Title level={5} style={{ color: '#4A4A4A', fontWeight: 700, fontSize: 14 }}>
                  {item?.name?.slice(0, 100)}
                </Title>
              </Col>
              <Col>
                <div
                  style={{
                    wordBreak: 'break-word',
                    fontSize: 14,
                    color: '#737373',
                    width: '39vw',
                    padding: '0px 126px 6px 0px',
                  }}
                  dangerouslySetInnerHTML={{ __html: item?.description?.slice(0, 200) }}
                ></div>
              </Col>
              <Col>
                {' '}
                <p style={{ color: '#737373' }}>{moment(item?.date).format('MMMM D, YYYY')}</p>
              </Col>
            </Col>
            <Col span={9}>
              {item?.videoURL && (
                <iframe
                  width="96%"
                  height="180"
                  src={
                    item?.videoURL.includes('youtube.com/playlist')
                      ? `https://www.youtube.com/embed/videoseries?list=${new URL(item.videoURL).searchParams.get('list')}`
                      : item?.videoURL.includes('youtube.com/watch')
                        ? `https://www.youtube.com/embed/${new URL(item.videoURL).searchParams.get('v')}`
                        : `https://www.youtube.com/embed/${item.videoURL.split('/').pop()}`
                  }
                  title="YouTube video playlist player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{ padding: 12 }}
                ></iframe>
              )}

            </Col>
          </Row>
        )
      )}
    </Row>
  );
};
