import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  :root {
    /*Typography*/
    --font: 'https://drive.google.com/drive/folders/1WDIWa_QmtuIXPKPqcypg5yO2xDGPH3yq?usp=sharing';

    /*font-size*/
    --base-font-size: 16px;
    --head-font-size: 22px;

    /*colors*/
    --yellowDark: #F5C247;
    --yelloLight: #F7D88F;
    --yello: #FCECC7;
    --gray: rgba(74, 74, 74, 1);
    --lightgray: #A2A2A2;


    --grayDark: #4A4A4A;
    --grayLight: #A5A5A5;
    --grayYellow: #A08649;
    --white: #ffff;
    --black: #303B44;

    /*font-weight*/
    --font-bold: 700;
  }

  * {
    margin: 0;
    padding: 0;
  }

  body {
    font-family: var(--font)
  }
  ::-webkit-scrollbar {
    width: 4px;
    height: 6px;
    background-color: #A9A9A9;
    padding: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #646464;
    border-radius: 3.5px;    
    padding: 5px;
    width: 12px;
  }
  #root {
    height: 100%
  }
  .ant-card{
    border-radius: none !important;
  }
  .ant-card.ant-card-bordered {
    border-radius: none !important;
  }

  .ant-card-bordered {
    border: none !important;
    border-radius: none !important;
  }

  .ant-tree-show-line .ant-tree-switcher {
    color: black !important;
    width: 10px;
  }
  .ant-tree-switcher .ant-tree-switcher_open{
    position: relative;
    top: -3px;
  }
.ant-tree .ant-tree-node-content-wrapper{
  padding: 0 !important;
}

  .ant-btn > span {
    display: contents;
  }
  .ant-card .ant-card-body{
    padding:  0 !important;
    border-radius: none !important;
    ol{
      padding-left: 14px;
      margin-top: 5px;
    }
  }
  .ant-drawer-body{
    padding: 0 !important;
  }
  .ant-card-body {
    display: flex;
  }
  .ant-card-body  :hover {
    background-color: var(--yelloLight);
  }
  .ant-tree .ant-tree-treenode {
    min-height: 28px;
    padding: 0px 0px 0px 40px;
  }
  .selectedCategory {
    border-bottom: 2px solid #4A4A4A;
  }
 .card-resource{
  span {
    background: inherit !important;
  }
 }
  .ant-input-affix-wrapper {
    background-color: unset;
    border: unset;
  }

  .ant-input-group-addon {
  display: none !important;
  }

  .ant-pagination-item {
    border: none !important;
    min-width: 15px !important;
  }
.allbutton{
  background: var(--yello);
  border: none;
  border-bottom: 2px solid #4A4A4A;
  color: #4A4A4A;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  border-radius: inherit;
  padding: 0 !important;
  &:hover{
    color: #303B44 !important;
  }
}
.selectedCategoryAll{
  background: var(--yello);
  border: none;
  color: rgba(74, 74, 74, 0.5);
  font-weight: 700;
&:hover{
    color: #303B44 !important;
  }
}
.ant-btn-default:not(:disabled):active,.ant-btn-default:not(:disabled):hover {
    color: var(--grayDark) ;
    border-color: black;
    text-decoration: underline;
}

  .ant-pagination-disabled {
    cursor: pointer !important;
  }
.selectedCategory:hover, .selectedCategory:active{
  text-decoration: underline;
}
  .resusner-card {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ant-tree-list {
    background-color: unset !important;
  }
  
  .ant-pagination-item-active {
    background-color: unset !important;
  } 



  .ant-input-affix-wrapper .ant-input{
    background-color: unset !important;
  }

  .ant-input-affix-wrapper .ant-input::placeholder{
    font-size: 16px;
    font-weight: 700;
    color: #A9A9A9;
  }

  .ant-input-affix-wrapper{
    padding: 4px 11px 0px 0px;
    box-shadow: none !important;
  }

 .ant-btn-default:not(:disabled):hover{
  text-decoration: none !important;
  border-color: rgb(74, 74, 74) !important;
 }

 .start-reading-btn:hover {
  color: var(--gray) !important;
  background-color: unset !important;
 }

 .ant-tag-checkable:not(.ant-tag-checkable-checked):hover{
  color: rgb(162, 162, 162);
  background-color: rgb(252, 236, 199);
 }

 .start-reading-btn{
  border-radius: unset !important;
 }
 .switcher-icon:hover {
  background-color: rgb(244, 195, 82); 
  border-radius: 50%;
}
.ant-tree-treenode-selected::before {
  background: rgb(244, 195, 82) !important;
}
  .ant-collapse-content-box {
    margin: 0 48px;
  }
  .ant-collapse .ant-collapse-content>.ant-collapse-content-box{
    padding: 16px 0px;
  }
  .ant-input-group >.ant-input:first-child,
  :where(.css-dev-only-do-not-override-12upa3x).ant-input-group .ant-input-group-addon:first-child {
    border: none;
    font-weight: 700;
  }
  .ant-tag-checkable {
    border: 0.5px solid rgb(245, 194, 71);
    background: rgb(252, 236, 199);
    color: rgb(162, 162, 162);
    font-size: 14px;
  }
  .tags-container {
    display: flex;
    flex-wrap: inherit;
    gap: 8px;
  }
  .pagination-container {
    margin-left: auto;
    margin-right: auto;
  }
  .ant-col-3{
    flex: 0 0 18.77%;
    max-width: none;
    }
  .ant-col-5 {
   max-width: 19.55%;
  }
  .ant-col-6{
    max-width: 20%;
  }
  .custom-input ::placeholder {
    font-size: 16px;
    font-weight: 700;
  }
 
  .ant-tree-switcher-leaf-line{
    display: none !important;
  }
  .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):active {
    background-color: var(--yellowDark);
  }
  .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    color: var(--grayDark);
    background-color: var(--yellowDark);
  }
  .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected{
    background-color: inherit;
    width: 49vw !important;
    position: relative;
    top: -2px;
  }
  .ant-tree .ant-tree-node-content-wrapper:hover{
    padding: 0px 10px !important;
    width: 49vw;
  }
  .ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle{
    line-height: 22px;
  }
  .ant-tree-title {
    color: #7F7F7F;
    font-size: 14px;
    font-weight: 700;
  &:hover {
    border-bottom: 2px solid rgb(74, 74, 74);
    font-weight: 700;
    color: rgb(74, 74, 74);
    cursor: pointer; 
  }
}
.ant-tabs .ant-tabs-tab:hover{
  color: #4A4A4A;
}
  
  .getResusName{
    width: 100%;
    text-align: start;
    font-size: '18px';
     color: 'rgba(74, 74, 74, 1)';
     font-weight: '400';
     line-height: '32px';
  p{
    margin: 2%;
    width: 98%;
    height: auto;
    overflow: hidden;
    word-wrap: break-word;
    white-space: pre-line;
    span{
   color: var(--grayDark)!important;
   background-color: inherit !important;
}
em{
  font-style: normal;
  color: var(--grayDark)!important;
}
  }
  img{
    height: 430px;
    height: 430px;
    width: 100%;
    object-fit: contain;
    justify-content: center;
    display: flex;
  }
  a{
    border-bottom:  1px solid rgba(74, 74, 74, 1);
  }
  li{
    position: relative;
    left: 30px;
    width: 93%;

  }
}
.getReference{
  p a{
    font-weight: 700;
    font-style: italic;
    color: rgba(74, 74, 74, 1) !important;

  }
}
.ant-btn-primary {
    background: none;
    padding: 0;
    color: rgba(74, 74, 74, 1);
    font-weight: 700;
    font-size: 18px;
    border-bottom: 2px solid var(--yellowDark);
    box-shadow: none !important;
}
.ant-btn-primary:not(:disabled):active,
.ant-btn-primary:not(:disabled):hover {
    color: var(--gray);
    background-color: var(--yellowDark);
}
.ant-tabs-nav-wrap{
  padding-left: 32px;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #4A4A4A;
  font-size: 16px;
  font-weight: 700;
  text-shadow: none !important;
}
.ant-tabs .ant-tabs-tab-btn{
  color: rgba(74, 74, 74, 0.50);
  font-size: 16px;
  font-weight: 700;
}
.ant-tabs-top >.ant-tabs-nav::before{
  border-bottom:  inherit !important;
}
.ant-tabs .ant-tabs-ink-bar{
  background: #4A4A4A !important;
  height: 2px;
}
@media  screen and (max-width: 1615px)
{
  .herotext {
    line-height: 20px !important;
    padding: 15px 0px 0px 30px !important;
  }
}
.referPrev{
  a, em, span {
    background-color: #F5C247 !important;
  }
}
.ant-collapse-header{
  padding: 4px 0 !important;
}
.ant-collapse-item{
border-bottom: none !important;}
.ant-btn > span {
    display: inherit !important;
    color: inherit;
  }
  .resusHome{
    justify-content: start !important;
    margin-left: 36px;
    :where(.css-dev-only-do-not-override-1jr9qlj).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover{
      color: inherit !important;
    }
  }
  .ant-spin .ant-spin-dot-item {
background-color: var(--grayDark);
  }
  .custom-next-button{
    position: absolute;
    top: 3px;
    left: 102%;
    cursor: pointer;
  }
  .custom-prev-button{
    position: absolute;
    right: 106%;
    top: 2px;
    cursor: pointer;
  }

  .scrollCustom::-webkit-scrollbar {
    width: 4px;
    height: 6px;
    background-color: white;
    padding: 5px;
  }

  .scrollCustom::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 3.5px;    
    padding: 5px;
    width: 12px;
  }
  .hover-effect:hover {
  background: rgba(245, 194, 71, 0.50);  
}
.getRexsusName{
  a{
    font-weight: 700;
    font-style: italic;
    color: #4A4A4A !important;
  }
  img{
    width: 100%;
  }
   p{
    color: #4A4A4A !important;
    span{
      color: #4A4A4A !important;
    }
  }
}
`;
