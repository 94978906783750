import { Card, Col, Row } from 'antd';
import { useGetAllPosts } from 'api/resources/use-get-posts';
import { useArticle } from 'context/wrapper';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DefaultImage from '../../../components/icons/deafult-image.png';
import styled from 'styled-components';
import moment from 'moment';

const DescriptionContainer = styled.div`
  max-height: 68px;
  overflow: hidden;
  font-size: 16px;
  max-width: 80%;
  line-height: 22px;
  img {
    display: none;
  }
  em {
    font-size: 12px;
    background: inherit;
  }
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    a{
      color: rgb(173, 173, 173) !important;
    }
  }
  span{
    color: inherit !important;
  }
`;

export const ResourcesPost = () => {
  const { posts, setPosts, setTotalPages, params, setParams, paramsModel, isPanelOpen } = useArticle();
  const { pathname, search } = useLocation();
  const navigator = useNavigate();

  useGetAllPosts(
    {
      ...params,
    },
    {
      enabled: true,
      onSuccess: (data: { totalPages(totalPages: number): unknown; data: string[] }) => {
        setPosts(data?.data);
        setTotalPages(data?.totalPages);
      },
    }
  );

  const handleCardClick = (postId: any): any => {
    navigator(`/post?id=${postId}`);
  };

  useEffect(() => {
    if (search !== '') {
      setParams({
        ...params,
        searchData: decodeURI(search.replace('?search=', '')),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <>
      <Row
        style={{
          maxHeight: pathname !== '/resources' && isPanelOpen ? 'calc(91vh - 315px)' : 'calc(91vh - 140px)',
          overflow: 'auto ',
          overflowX: 'hidden',
          height: 'auto',
          padding: pathname === '/models' ? '' : '32px 0',
          background: 'white',
          width: '100%',
        }}
        className="scrollCustom"
      >
        {paramsModel?.searchData !== ''
          ? ''
          : posts?.map((post: any) => {
            const momentDate = moment(post.date);
            const formattedDate = momentDate.isValid() ? momentDate.format('YYYY-MM-DD') : '';
            return (
              <Col span={pathname === '/resources' ? 12 : 24} key={post.id}>
                <Card
                  key={post.id}
                  onClick={() => handleCardClick(post.id)}
                  className={pathname === '/resources' ? '' : 'resusHome'}
                >
                  <Card
                    className="card-resource"
                    style={{ display: 'grid', cursor: 'pointer', padding: '15px 5px 9px 15px ', height: '175px', width: '100%' }}
                  >
                    <Row
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 'calc(100% - 220px)'
                      }}
                    >
                      <Col style={{
                        display: 'grid', height: '100px',
                      }}>
                        <p
                          style={{
                            width: '100%',
                            fontSize: '14px',
                            fontWeight: '700',
                            lineHeight: '15px',
                            color: '#4A4A4A',
                            wordBreak: 'break-word',
                            maxHeight: 33,
                            overflow: 'hidden',
                          }}
                        >
                          {post.name}
                        </p>
                        <p
                          style={{
                            width: '100%',
                            color: '#ADADAD',
                            fontSize: '14px',
                            lineHeight: '15px',
                          }}
                        >
                          <DescriptionContainer
                            className="data-container"
                            style={{ wordBreak: 'break-word' }}
                            dangerouslySetInnerHTML={{ __html: post?.description?.slice(0, 17000) }}
                          ></DescriptionContainer>
                        </p>
                      </Col>
                      <p style={{ color: '#A2A2A2', fontSize: '14px' }}>
                        {formattedDate}
                        {post?.['category.label'] ? (
                          <button
                            style={{
                              color: '#A2A2A2',
                              backgroundColor: 'inherit',
                              border: 'none',
                              padding: '2px',
                              fontSize: '14px',
                              borderRadius: '4px',
                              marginLeft: '35px',
                              fontWeight: '700',
                            }}
                          >
                            {post?.['category.label']}
                          </button>
                        ) : null}
                      </p>
                    </Row>
                    <Row style={{ minWidth: '220px' }}>
                      {post?.preview ? (
                        <img
                          src={post?.preview}
                          style={{ height: '140px', width: '224px', objectFit: 'cover' }}
                          alt={'Preview of Post'}
                        />
                      ) : (
                        post?.videoURL ? (
                          <iframe
                          width="220px"
                          height="140px"
                          src={
                            post?.videoURL.includes('youtube.com/playlist')
                              ? `https://www.youtube.com/embed/videoseries?list=${new URL(post?.videoURL).searchParams.get('list')}`
                              : post?.videoURL.includes('youtube.com/watch')
                                ? `https://www.youtube.com/embed/${new URL(post?.videoURL).searchParams.get('v')}`
                                : `https://www.youtube.com/embed/${post?.videoURL.split('/').pop()}`
                          }
                          title="YouTube video playlist player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                                                  ></iframe>
                        ) : (
                          <img
                            src={DefaultImage}
                            style={{ height: '140px', width: '220px', objectFit: 'cover' }}
                            alt={'Preview of Post'}
                          />
                        )
                      )}


                    </Row>
                  </Card>
                </Card>
              </Col>
            );
          })}
      </Row>
    </>
  );
};
