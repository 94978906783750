import { Outlet, createBrowserRouter } from 'react-router-dom';
import { HomeLayout } from './components/layouts';
import { PATHS } from './helpers/constants';
import { Resources } from 'pages/resources';
import { Models } from './pages/models';
import { Home } from './pages/home';
import { PublicModel } from 'pages/public/model';
import { PublicPost } from 'pages/public/post';
import { PublicHero } from 'pages/public/hero';
import { Info } from 'pages/info';
import { PublicDynamic } from 'pages/models/dinamik/publicDynamic';

export const router = createBrowserRouter([
  {
    element: <HomeLayout />,
    children: [
      {
        path: PATHS.ROOT,
        element: <Home />,
      },
      {
        path: PATHS.MODELS,
        element: <Models />,
      },
      {
        path: PATHS.RESOURCES,
        element: <Resources />,
      },
    ],
  },
  {
    element: <Outlet />,
    children: [
      {
        path: PATHS.PUBLIC_MODEL,
        element: <PublicModel />,
      },
      {
        path: PATHS.PUBLIC_DYNAMIC,
        element: <PublicDynamic />,
      },
      {
        path: PATHS.PUBLIC_POST,
        element: <PublicPost />,
      },
      {
        path: PATHS.PUBLIC_POST,
        element: <PublicHero />,
      },
      {
        path: PATHS.PUBLIC_INFO,
        element: <Info />,
      },
    ],
  }
]);
