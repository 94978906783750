import { Layout as LayoutComponent, Row, Spin } from 'antd';
import styled from 'styled-components';
import { Header } from '../headers';
import { HeaderArticle } from '../headers/article';
import { Tags } from '../tags';
import { Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';

const Layout = styled(LayoutComponent)`
  background: white;
  height: 100vh;
  overflow: hidden;
`;

const { Content: ContentComponent } = LayoutComponent;

const Content = styled(ContentComponent)`
  margin: 0 auto;
`;

export const HomeLayout = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  return (
    <Layout>
      {' '}
      <Header />
      {isLoading ? (
        <Row justify={'center'}>
          <Spin size="large" style={{ position: 'absolute', top: '50%', left: '50%' }} />
        </Row>
      ) : (
        <>
          <HeaderArticle />
          <Content style={{ width: '100%' }}>
            <Tags />
            <Outlet />
          </Content>
        </>
      )}
    </Layout>
  );
};
