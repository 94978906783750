import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import client from 'api/client';
export const url = `/public/getPostById`;

export const useGetModelById = (
  id: string,
  options:
    | (Omit<UseQueryOptions<any, unknown, any, string[]>, 'initialData' | 'queryFn' | 'queryKey'> & {
        initialData?: (() => undefined) | undefined;
      })
    | undefined
) => {
  const result = useQuery(
    [url, id],
    async () => {
      const response = await client.get(`${url}/${id}`);
      return response as UseQueryOptions;
    },
    options
  );

  const { data, isSuccess, isLoading } = result;
  return {
    data: isSuccess ? data : [],
    isLoading,
  };
};
