import { useEffect, useState } from 'react';
import { Button, Col, Row, Tabs, TabsProps, Tree } from 'antd';
import { GetAllModelavorum } from 'api/modelavorum';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import { ReactComponent as Model } from '../../components/icons/model.svg';

import { useArticle } from 'context/wrapper';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATHS } from 'helpers/constants';
import { EventDataNode, DataNode } from 'antd/es/tree';
import { Dinamik } from './dinamik';
import { Chart } from './chart/chart';

export interface ITreeDataType {
  [x: string]: any;
  id: string;
  title: string;
  children?: ITreeDataType[];
}

type TreeData = ITreeDataType[];

export const Models = () => {
  const {
    postsModel,
    setPostsModel,
    setTotalPagesModel,
    paramsModel,
    setParamsModel,
    params,
    setSelectedCategory,
    isPanelOpen,
  } = useArticle();
  const { pathname, search } = useLocation();
  const navigator = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [previewModel, setPreviewModel] = useState<any>();
  const [showLeafIcon, setShowLeafIcon] = useState<boolean | React.ReactNode>(false);

  const transformData = (data: TreeData): TreeData => {
    return data?.map((item) => {
      const transformedItem: ITreeDataType = {
        id: item.id,
        title: item?.name,
        children: item.children ? transformData(item.children) : [],
      };
      return transformedItem;
    });
  };

  GetAllModelavorum(
    {
      ...paramsModel,
    },
    {
      enabled: true,
      onSuccess: (data: { totalPages(totalPages: number): unknown; data: string }) => {
        setPostsModel(data?.data);
        setTotalPagesModel(data?.totalPages);
      },
    }
  );
  const treeData: any = postsModel ? transformData(postsModel) : [];
  const handleButtonClick = (): void => {
    navigator(PATHS.MODELS);
  };
  const [select, setSelect] = useState();

  const handleItemClick = (item: EventDataNode<DataNode> | any) => {
    setSelect(item);
    navigator(`/model?id=${item}`);
  };

  useEffect(() => {
    if (search !== '') {
      setParamsModel({
        ...paramsModel,
        searchData: decodeURI(search.replace('?search=', '')),
      });
    }
    setSelectedCategory('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  useEffect(() => {
    const preview = sessionStorage.getItem('preview');
    if (preview) {
      setPreviewModel(preview);
      sessionStorage.removeItem('preview');
    }
  }, []);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const { DirectoryTree } = Tree;
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: ' Քաղաքական համակարգի օնթոլոգիայի սխեմա',
      children: <Chart />,
    },
    {
      key: '2',
      label: ' Դինամիկ մոդելավորում',
      children: <Dinamik />,
    },
  ];
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);

  const saveExpandedKeysToSessionStorage = (keys: React.Key[]) => {
    sessionStorage.setItem('expandedKeys', JSON.stringify(keys));
  };

  const loadExpandedKeysFromSessionStorage = () => {
    const storedKeys = sessionStorage.getItem('expandedKeys');
    if (storedKeys) {
      const parsedKeys = JSON.parse(storedKeys);
      setExpandedKeys(parsedKeys);
    }
  };

  useEffect(() => {
    loadExpandedKeysFromSessionStorage();
    sessionStorage.removeItem('expandedKeys');
    if (!expandedKeys || expandedKeys.length === 0) {
      saveExpandedKeysToSessionStorage(['0-0', '0-1', '0-2', '0-3']);
      setExpandedKeys(['0-0', '0-1', '0-2', '0-3']);
    }
  }, [expandedKeys]);




  const onExpand = (expandedKeys: React.Key[]) => {
    saveExpandedKeysToSessionStorage(expandedKeys);
    setExpandedKeys(expandedKeys);
  };

  return (
    <Col
      style={{
        height: '200px',
        position: 'relative',
        top: '62px',
      }}
    >
      <Row style={{ height: '32%', background: 'var(--yello)' }}>
        <Col
          span={8}
          style={{
            height: '100vh',
            boxShadow: pathname === '/models' ? 'rgba(74, 74, 74, 0.05) 5px 0px 0px' : 'none',
          }}
        >
          <Button
            type="primary"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleButtonClick}
            style={{
              backgroundColor: 'inherit',
              transition: 'background-color 0.3s',
              background: 'var(--yello)',
              border: 'none',
              fontWeight: '700',
              color: '#4A4A4A',
              borderBottom: '2px solid #4A4A4A',
              marginLeft: '50px',
              borderRadius: 'unset',
              padding: 'unset',
              position: 'relative',
              top: '12px',
              fontSize: '16px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Մոդելներ
            {pathname === '/models' ? (
              ''
            ) : (
              <CaretRightOutlined
                style={{
                  transform: isHovered ? 'translateX(10px' : 'rotate(0deg)',
                  transition: 'transform 0.3s',
                }}
              />
            )}
          </Button>
        </Col>
        <Col span={16} style={{ boxShadow: 'rgba(74, 74, 74, 0.05) 5px 0px 0px', position: 'relative', zIndex: 1 }}>
          {pathname === '/models' && (
            <Tabs defaultActiveKey={search && pathname === '/models' ? '2' : '1'} items={items} />
          )}
        </Col>
      </Row>

      <div
        className="scrollCustom"
        style={
          pathname !== '/'
            ? {
                height: isPanelOpen ? 'calc(91vh - 140px)' : 'calc(91vh - 175px)',
                overflow: 'auto ',
                overflowX: 'hidden',
                background: 'white',
                width: 'calc(100% - 2px)',
              }
            : {
                height: isPanelOpen ? 'calc(91vh - 330px)' : 'calc(91vh - 165px)',
                overflow: 'auto ',
                overflowX: 'hidden',
              }
        }
      >
        {params?.searchData !== '' ? (
          ''
        ) : (
          <Row style={pathname === '/models' ? { display: 'grid', gridTemplateColumns: '30% auto' } : {}}>
            <DirectoryTree
              onExpand={onExpand}
              expandedKeys={expandedKeys?.length === 0 ? ['0-0, 0-1, 0-2, 0-3'] : expandedKeys}
              showLine={showLeafIcon ? { showLeafIcon } : true}
              showIcon={true}
              switcherIcon={<CaretDownOutlined className="switcher-icon" />}
              icon={<Model />}
              onSelect={(selectedKeys, info) => {
                if (selectedKeys[0] === 'someCondition') {
                  setShowLeafIcon(true);
                } else {
                  setShowLeafIcon(false);
                }
              }}
              treeData={treeData}
              style={
                pathname === '/models'
                  ? {
                      width: '100%',
                      background: '#FAFAFA',
                      overflow: 'hidden',
                    }
                  : { width: '50vw' }
              }
              titleRender={(node: any) => (
                <span
                  style={{
                    background: node.id === select || previewModel === node.id ? 'rgb(245, 194, 71)' : 'inherit',
                  }}
                  onClick={() => {
                    sessionStorage.setItem('preview', node?.id), handleItemClick(node?.id);
                  }}
                >
                  {node.title}
                </span>
              )}
            />
          </Row>
        )}
      </div>
    </Col>
  );
};
