import { useLocation } from 'react-router-dom';
import { useGetMostPost } from 'api/mostpost';
import { Wrapper } from 'pages/public/wrapper';
import { useGetModelDynamic } from 'api/modelDynamic/modelGetIdDynamic';

export const PublicDynamic = () => {
  const { search } = useLocation();

  const queryParams = new URLSearchParams(search);
  const id = queryParams.get('id');
  const { data: dataMost } = useGetMostPost({});

  const { data } = useGetModelDynamic(id as string, { enabled: !!id });

  return <Wrapper data={data} dataMost={dataMost} />;
};