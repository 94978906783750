import { useQuery } from '@tanstack/react-query';

import client from 'api/client';

export const urlGetAllCategory = '/public/getCategory';

export const GetAllCategory = (
  params: {
    page: number;
    limit: number;
  },
  options?: any
) => {
  const result: any = useQuery(
    [urlGetAllCategory, params.page, params.limit],
    async () => {
      const response = await client.get(`${urlGetAllCategory}/${params.page}/${params.limit}`);
      return response;
    },
    options
  );
  const { data, isSuccess } = result;

  const total = data?.totalPages;

  return {
    ...result,
    data: isSuccess ? data : [],
    totalPages: isSuccess ? total : 0,
  };
};
