import { useQuery } from '@tanstack/react-query';

import client from 'api/client';

export type PostsTypes = {
  data: {
    id: string;
    name: string;
    description: string;
    view: number;
    preview: string | undefined;
    status: string;
    update: string;
    date: string;
    category: string;
  }[];
};

export const GetAllPostsUrl = '/public/getPosts';

export const useGetAllPosts = (
  params: any & {
    searchData: string;
    page: number;
    limit: number;
    tags: string[];
  },
  options?: any
): any => {
  const result = useQuery({
    queryKey: [GetAllPostsUrl, params],
    queryFn: async () => {
      const response = await client.post(`${GetAllPostsUrl}/${params.page}/${100}`, {
        searchData: params.searchData,
        tags: params.tags,
        category: params.category,
      });
      return response;
    },
    ...options,
  });

  const { data, isSuccess } = result;

  return {
    ...result,
   data: isSuccess ? data as PostsTypes[] : [],
  };
};
