import styled from 'styled-components';
import { Button, Col, Row } from 'antd';
import { COLORS } from '../../helpers/constants';
import HeaderArticleUrl from 'components/icons/article.png';
import { useGetHeaderArticle } from '../../api/article/use-get-header-article';
import { CaretRightOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useArticle } from 'context/wrapper';
const Wrapper = styled.div`
  background: ${COLORS.PRIMARY.ORANGE};
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  max-height: 228px;
  position: relative;
  top: 64px;
  height: 304px;

  img {
    height: 100%;
  }

  .data-container {
    max-height: 6.3em;
    overflow: hidden;
    padding: 20px 0;
    font-size: 16px;
    max-width: 80%;
    img {
      display: none;
    }
    span{
      background: inherit !important;
    }
  }

  .data-container p {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .herotext {
    line-height: 20px;
    padding: 36px 0 0 48px;
    max-width: 73vw;
  }
  .herotitle {
    font-weight: 700;
    font-size: 22px;
    color: var(--gray);
    line-height: 24px;
  }
`;

export const HeaderArticle = () => {
  const { data }: { data: any } = useGetHeaderArticle({});
  const navigator = useNavigate();
  const { isPanelOpen } = useArticle() ?? {};

  const showDrawer = () => {
    navigator(`/post?id=${data?.id}`);
  };
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const { pathname } = useLocation();

  if (!isPanelOpen || pathname === '/models' || pathname === '/resources') {
    return null;
  }

  return (
    <Wrapper>
      <div>
        <Row className="herotext">
          <Col span={24}>
            <h3 style={{ wordBreak: 'break-word' }} className="herotitle">
              {data?.title && data.title.length > 80
                ? `${data.title.slice(0, 80)}...`
                : data?.title}
            </h3>

          </Col>
          <Col span={24}>
            <div
              style={{ wordBreak: 'break-word' }}
              className="data-container"
              dangerouslySetInnerHTML={{ __html: data?.description?.slice(0, 1000) }}
            ></div>
          </Col>
          <Col style={{ padding: '16px 0px' }}>
            <Button
              type="primary"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={showDrawer}
              style={{
                backgroundColor: 'inherit',
                transition: 'background-color 0.3s',
                color: '#4A4A4A',
                fontSize: '18px',
                fontWeight: '700',
                borderRadius: 'inherit',
                borderBottom: '2px solid #4a4a4a',
              }}
            >
              Կարդալ ավելին
              <CaretRightOutlined
                style={{
                  transform: isHovered ? 'translateX(10px' : 'rotate(0deg)',
                  transition: 'transform 0.3s',
                }}
              />
            </Button>
          </Col>
        </Row>
      </div>
      <div>
        <img src={HeaderArticleUrl} alt="general article image" />
      </div>
    </Wrapper>
  );
};
