import { Col, Row } from 'antd';
import { Header } from 'components/headers';
import { MerMasin } from './merMasin';
import { MerTimy } from './merTimy';

export const Info = () => {
  return (
    <>
      <Header />
      <Row style={{ position: 'relative', top: 64 }}>
        <Col span={24}>
          <MerMasin />
        </Col>
        <Col span={24} style={{ padding: '0 0 50px ' }}>
          <MerTimy />
        </Col>
      </Row>
    </>
  );
};
