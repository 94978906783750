export const customData = {
  id: 'Հիմնական Հասկացություններ',
  children: [
    {
      id: 'Դիրքը',
      children: [
        {
          id: 'Սուբյեկտները',
          children: [
            {
              id: 'Պետություններ',
              children: [
                { id: 'Հայաստան' },
                { id: 'Վրաստան' },
                { id: 'Ադրբեջան' },
                { id: 'Թուրքիա' },
                { id: 'Իրան' },
                { id: 'Ռուսաստանի Դաշնություն' },
                { id: 'ԱՄՆ' },
                { id: 'Ֆրանսիա' },
                { id: 'Չինաստան' },

                { id: '․․․' },
              ],
            },
            {
              id: 'Վերպետական միջազգային կազմակերպություններ',
              children: [{ id: 'ՄԱԿ' }, { id: 'ԵՄ' }, { id: ',,,' }],
            },
            {
              id: 'Քաղաքական այլ խմբեր',
              children: [
                { id: 'Չճանաչված պետական կազմավորումներ' },
                { id: 'Անկախության համար պայքարող ժողովուրդներ' },
                { id: 'Վերազգային կորպորացիաներ' },
                { id: 'Էթնոկրոնական համայնքներ' },
                { id: '՛՛՛' },
              ],
            },
          ],
        },
        {
          id: 'Կապը',
          children: [
            { id: 'Ներքին հարաբերությունները' },
            {
              id: 'Արտաքին (միջազգային) հարաբերությունները',
              children: [
                { id: 'Հարաբերությունների բացակայությունը' },
                { id: 'Չեզոք հարաբերություններըկ' },
                { id: 'Կոնֆլիկտային հարաբերությունները' },
                {
                  id: 'Համագործակցային հարաբերությունները',
                  children: [
                    { id: 'Քաղաքական (դիվանագիտական) հարաբերություններըի' },
                    { id: 'Առեւտրատնտեսական հարաբերությունները' },
                    { id: 'Մշակութային հարաբերությունները' },
                    { id: 'Ռազմաանվտանգային հարաբերությունները' },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'Կշիռը',
      children: [
        {
          id: 'Ուժը',
          children: [
            {
              id: 'Ներքին մղումը',
              children: [
                {
                  id: 'Հանրության ինքնորոշման աստիճանը',
                  children: [
                    { id: 'Ինքնակերպարը' },
                    { id: 'Գործունեության վայրը' },
                    { id: 'Պետության ձեւավորման դրդապատճառները եւ նպատակըկ' },
                    { id: 'Թշնամու կերպարը' },
                    { id: 'Գործունեության ձեւը' },
                    { id: 'Գործունեության պայմանները' },
                  ],
                },
                { id: 'Հանրության քաղաքական գիտակցության աստիճանը' },
                { id: 'Պետական շահերի գիտակցման աստիճանը' },
                { id: 'Կառավարության քաղաքական կամքի առկայության աստիճանը' },
                { id: 'Կառավարության հանդեպ հանրային վստահության (տրամադրությունների) աստիճանը' },
                { id: 'Հանրության կոլեկտիվ վախերի աստիճանը' },
                { id: 'Խմբային համոզմունքների ազդեցության աստիճանը' },
              ],
            },
            { id: 'Արտաքին ազդակը' },
          ],
        },
        {
          id: 'Ներուժը',
          children: [
            {
              id: 'Կառուցվածքները',
              children: [
                {
                  id: 'Քաղաքական կառուցվածքը',
                  children: [
                    {
                      id: 'Կառավարությունը',
                      children: [
                        { id: 'Գործադիր իշխանությունը' },
                        { id: 'Օրենսդիր իշխանությունը' },
                        { id: 'Դատական իշխանությունը' },
                      ],
                    },
                    { id: 'Կուսակցական համակարգը' },
                  ],
                },
                {
                  id: 'Ռազմական կառուցվածքը',
                  children: [
                    { id: 'Զինված ուժերի թվաքանակը եւ ռազմական փորձը' },
                    { id: 'Զինված ուժերի կառուցվածքն ու ստորաբաժանումները' },
                    { id: 'Զենքը, սպառազինությունը եւ ռազմական բյուջեն' },
                    { id: 'Հրամանատարությունը եւ զինվորական կադրերի որակը' },
                  ],
                },
                {
                  id: 'Տնտեսական կառուցվածքը',
                  children: [
                    {
                      id: 'Արդյունաբերությունը',
                      children: [
                        {
                          id: 'Թեթեւ արդյունաբերությունը',
                          children: [
                            { id: 'Տեքստիլի (թելի) արտադրությունը' },
                            { id: 'Կարի (գործվածքի) արտադրությունը' },
                            { id: 'Կաշվի արտադրությունը' },
                            { id: 'Մորթու արտադրությունը' },
                            { id: 'Գորգերի արտադրությունը' },
                          ],
                        },
                        {
                          id: 'Մեքենաշինությունը',
                          children: [
                            { id: 'Ավիացիոն արդյունաբերությունը' },
                            { id: 'Ավտոմոբիլային արդյունաբերությունը' },
                            { id: 'Գործիքաշինական արդյունաբերությունը' },
                            { id: 'Էլեկտրատեխնիկայի արդյունաբերությունը' },
                            { id: 'Հաստոցաշինությունը' },
                            { id: 'Նավաշինությունը' },
                            { id: 'Ռադիոարդյունաբերությունը' },
                            { id: 'Սարքաշինությունը' },
                            { id: 'Տրակտորաշինությունը' },
                          ],
                        },
                        {
                          id: 'Հանքարդյունաբերությունը',
                          children: [
                            { id: 'Հանքաէներգետիկ հումքի արդյունահանումը' },
                            { id: 'Սեւ մետաղային հանքաքարերի արդյունահանումը ' },
                            { id: 'Գունավոր մետաղային հանքաքարերի արդյունահանումը' },
                            { id: 'Լեռնաքիմիական հումքի արդյունահանումը' },
                            { id: 'Ոչ մետաղային հումքի և շինանյութային հումքի արդյունահանումը' },
                            { id: 'Թանկարժեք եւ կիսաթանկարժեք քարերի արդյունահանումը' },
                            { id: 'Հանքային ջրերի արդյունահանումը' },
                          ],
                        },
                        { id: 'Գունավոր մետալուրգիան' },
                        { id: 'Քիմիական արդյունաբերությունը' },
                        {
                          id: 'Սննդի արդյունաբերությունը',
                          children: [
                            { id: 'Մսամթերքի արդյունաբերությունը' },
                            { id: 'Ձկնամթերքի արդյունաբերությունը' },
                            { id: 'Մրգերի և բանջարեղենի արդյունաբերությունը' },
                            { id: 'Ձեթի ու յուղի վերամշակման արդյունաբերությունը' },
                            { id: 'Կաթնամթերքի արդյունաբերությունը' },
                            { id: 'Ալյուրի արդյունաբերությունը' },
                            { id: 'Կրախմալի արդյունաբերությունը' },
                            { id: 'Հացաբուլկեղենի արդյունաբերությունը' },
                            { id: 'Շաքարի արդյունաբերությունը' },
                            { id: 'Հրուշակեղենի արդյունաբերությունը' },
                            { id: 'Մակարոնեղենի արդյունաբերությունը' },
                            { id: 'Պատրաստի սննդի և կիսաֆաբրիկատների արդյունաբերությունը' },
                            { id: 'Պահածոների արդյունաբերությունը' },
                            { id: 'Ջրերի և հանքային ջրերի արդյունաբերությունը' },
                            { id: 'Գազավորված և չգազավորված ըմպելիքների արդյունաբերությունը' },
                            { id: 'Ալկոհոլային խմիչքների արդյունաբերությունը' },
                            { id: 'Ծխախոտի արդյունաբերությունը' },
                            { id: 'Անասնակերի արդյունաբերությունը' },
                          ],
                        },
                        {
                          id: 'Անտառային արդյունաբերությունը',
                          children: [
                            { id: 'Փայտամշակման արդյունաբերությունը' },
                            { id: 'Ցելյուլոզաթղթային արտադրությունը' },
                            { id: 'Փայտաքիմիական արտադրությունը' },
                          ],
                        },
                        {
                          id: 'Էլեկտրոէներգետիկան',
                          children: [
                            { id: 'Հիդրոէներգետիկան' },
                            { id: 'Միջուկային էներգետիկան' },
                            { id: 'Ջերմաէներգետիկան' },
                            { id: 'Հողմաէներգետիկան' },
                            { id: 'Արևային էներգետիկան' },
                            { id: 'Բիոէներգետիկան' },
                            { id: 'Երկրաջերմային էներգետիկան' },
                            { id: 'Օվկիանոսային էներգետիկան' },
                          ],
                        },
                      ],
                    },
                    { id: 'Գյուղատնտեսությունը' },
                  ],
                },
              ],
            },
            {
              id: 'Ենթակառուցվածքները',
              children: [
                { id: 'Ավտոճանապարհները' },
                { id: 'Կամուրջները' },
                { id: 'Նավահանգիստները' },
                { id: 'Օդանավակայանները' },
                { id: 'Էլեկտրահաղորդման գծերը' },
                { id: 'Նավթամուղները' },
                { id: 'Գազամուղները' },
                { id: 'Երկաթգծերը' },
                { id: 'Ջրամբարները' },
                { id: 'Ջրանցքները' },
                { id: 'Խմելու ջրի ջրամբարներն ու ջրագծերը' },
                { id: 'Ավտոտրանսպորտը' },
                { id: 'Երկաթուղին' },
                { id: 'Օդանավերի պարկը' },
                { id: 'Էլեկտրական կապը (տելեկոմունիկացիան) եւ դրա միջոցները' },
                { id: 'Առողջապահական հաստատությունները' },
                { id: 'Հանգստի, սպորտի եւ զվարճանքի հաստատությունները' },
                { id: 'Կրթական հաստատությունները' },
                { id: 'Գիտական հաստատությունները' },
                { id: 'Մշակութային հաստատությունները' },
                { id: 'Հանրային կարգը եւ անվտանգությունն ապահովող հաստատությունները' },
                { id: 'Աղբահանության եւ աղբավերամշակման ծառայությունները' },
              ],
            },
            { id: 'Բնական պայմանները' },
            {
              id: 'Ռեսուրսները եւ աղբյուրները',
              children: [
                {
                  id: 'Բնական ռեսուրսները',
                  children: [
                    { id: 'Հողային ռեսուրսները', children: [{ id: 'Գյուղատնտեսական նշանակության հողերը ' }] },
                    { id: 'Ջրային ռեսուրսները' },
                    { id: 'Կենսաբանական ռեսուրսները', children: [{ id: 'Անտառային ռեսուրսները ' }] },
                    {
                      id: 'Հանքային ռեսուրսները',
                      children: [
                        { id: 'Վառելիքաէներգետիկ օգտակար հանածոները' },
                        { id: 'Մետաղային օգտակար հանածոները' },
                        { id: 'Ոչ մետաղային օգտակար հանածոները' },
                      ],
                    },
                    {
                      id: 'Էներգետիկ ռեսուրսները',
                      children: [
                        { id: 'Չվերականգնվող էներգետիկ ռեսուրսները' },
                        { id: 'Վերականգնվող էներգետիկ ռեսուրսները' },
                        { id: 'Միջուկային էներգետիկ ռեսուրսները' },
                      ],
                    },
                    { id: 'Համաշխարհային օվկիանոսի ռեսուրսները' },
                    { id: 'Ռեկրեացիոն ռեսուրսները' },
                  ],
                },
                {
                  id: 'Մարդկային ու մարդածին ռեսուրսները',
                  children: [
                    { id: 'Մարդկային ռեսուրսները' },
                    { id: 'Ֆինանսական ռեսուրսները' },
                    { id: 'Մարդածին ռեկրեացիոն ռեսուրսները' },
                    { id: 'Երկրորդային ռեսուրսները' },
                  ],
                },
              ],
            },
            { id: 'Պահուստները (ռեզերվները)' },
            { id: 'Հնարավորությունները' },
          ],
        },
      ],
    },
    {
      id: 'Գործոնը',
      children: [
        { id: 'Գաղափարական գործոններ' },
        { id: 'Պատմական գործոններ' },
        { id: 'Տնտեսական գործոններ' },
        { id: 'Սոցիալական գործոններ' },
        { id: 'Քաղաքական ինստիտուտներ' },
        { id: 'Հանրային կարծիք' },
        { id: 'Միջազգային հարաբերություններ' },
        { id: 'Քաղաքական գործիչներ' },
        { id: 'Զանգվածային լրատվության միջոցներ' },
        { id: 'Եկեղեցի' },
      ],
    },
  ],
};
