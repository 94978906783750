import { useQuery } from '@tanstack/react-query';

import client from 'api/client';


export const GetAllModelUrl = '/public/static/getModels';

export const GetAllModelavorum = (
  params: any & {
    searchData: string;
    page: number;
    limit: number;
    tags: string[];
  },
  options?: any,
): any => {
  const result = useQuery(
    [GetAllModelUrl, params],
    async () => {
      const response = await client.post(
        `${GetAllModelUrl}/${params.page}/${100}`,
        {
          searchData: params.searchData,
          tags: params.tags,
        },
      );
      return response;
    },
    options,
  );
  const { data, isSuccess } = result;

  return {
    ...result,
    data: isSuccess ? data : [],
  };
};
